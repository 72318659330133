// Dependencies
import React from "react"
import SEO from "../components/seo"
import { FiPhoneCall, FiMail } from "react-icons/fi"
import logo from "../images/logo.svg"
import { FaLinkedinIn } from "react-icons/fa"
import { Container } from "react-bootstrap"

function Page() {
  return (
    <>
      <SEO />
      <div className="main">
        <Container>
          <header>
            <img src={logo} alt="Martinez Medina Procesos Industriales" />
          </header>
          <div className="contact-details">
            <b>Martínez Medina Procesos Industriales S.L.</b>
            <span>Calle Alisios, 3. Polígono Industrial Cadesa</span>
            <span>41702 Dos Hermanas (Sevilla)</span>
            <span>
              <FiPhoneCall className="icon" />
              <a href="tel:+34954721079">+34 954 721 079</a>
            </span>

            <span>
              <FiMail className="icon" />
              <a href="mailto:info@martinez-medina.com">
                info@martinez-medina.com
              </a>
            </span>
          </div>
          <div className="titles">
            <h1>Sitio en construcción</h1>
            <h2>
              Mientras tanto, pongase en contacto con nosotros, estaremos
              encantados de atenderles.
            </h2>
          </div>
        </Container>
        <footer>
          <div className="social">
            <b>Siguenos en las redes sociales:</b>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/martinez-medina-procesos-industriales-sl"
              alt="Facebook"
            >
              <FaLinkedinIn />
            </a>
          </div>
          <span className="credits">
            {`© ${new Date().getFullYear()} Martínez Medina Procesos Industriales S.L.`}
          </span>
        </footer>
      </div>
    </>
  )
}

export default Page
